<template>
  <div>
    <div class="p-1">
      <div class="alert alert-warning d-inline mt-1">
        <p-icon name="bi-info-circle" class="mr-half" />
        Please select the checkboxes where you want to upload the image.
      </div>
    </div>
    <div class="p-half">
      <dx-util-check-box v-for="(logo, key) in logos" :key="key" :value="uploadSelections[key]" :text="formatStr(key)" class="mx-half" @valueChanged="updateObject($event, key)" />
      <input ref="fileInput" type="file" hidden @change="onFileChange">
      <dx-util-button :disabled="disableUpload" text="Upload" icon="bi bi-image" type="success" class="ml-1" @click="uploadLogo" />
    </div>
    <div class="d-flex text-center">
      <div v-for="(logo, key) in logos" :key="key" class="m-1 p-1 bg-light-primary rounded" :class="uploadSelections[key] ? 'border-success' : ''" role="button" @click="select(key)">
        <div class="p-half mb-1 border-bottom">
          <h6 class="">
            {{ formatStr(key) }}
          </h6>
        </div>
        <img :src="logo || defaultImageUrl" alt="logo" height="150px">
      </div>
    </div>
  </div>
</template>

<script>
import tenantService from '@/http/requests/tenant/tenan.settings'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  data() {
    return {
      logos: [],
      uploadSelections: {
        FOOTER_LOGOS: false,
        TENANT_LOGOS: false,
        COMPANY_LOGOS: false,
        USER_LOGOS: false,
        INVOICE_LOGOS: false,
      },
      defaultImageUrl,
    }
  },
  computed: {
    disableUpload() {
      return Object.values(this.uploadSelections).every(el => el === false)
    },
    fileInputRef() {
      return this.$refs.fileInput
    },
  },
  async mounted() {
    await this.getTenantSettings()
  },
  methods: {
    async getTenantSettings() {
      const currentTenantSettings = await tenantService.currentTenantSettings()
      this.logos = currentTenantSettings.data?.filter(el => el.key === 'PUBLIC_RESOURCE')[0]?.extraObject
    },
    formatStr(v) {
      return v.replace('_', ' ').slice(0, -1)
    },
    uploadLogo() {
      this.fileInputRef.click()
    },
    async onFileChange(event) {
      const imageFile = event.target.files[0]
      const formData = new FormData()
      formData.append('file', imageFile)

      const json = []
      Object.keys(this.uploadSelections).forEach(key => {
        if (this.uploadSelections[key] === true) {
          json.push({ publicFolder: key })
        }
      })

      const payload = {
        publicResources: json,
      }

      formData.append('publicResources', JSON.stringify(payload))

      try {
        await tenantService.uploadLogo(formData)
        const updatedSettings = await this.getTenantSettings()
        this.logos = updatedSettings.data.filter(el => el.key === 'PUBLIC_RESOURCE')[0].extraObject
      } catch (error) {
        // Handle error
      } finally {
        this.$refs.fileInput.value = null
      }

      this.resetObject()
    },
    updateObject(e, key) {
      this.$set(this.uploadSelections, key, e.value)
    },
    resetObject() {
      this.uploadSelections = {
        FOOTER_LOGOS: false,
        TENANT_LOGOS: false,
        COMPANY_LOGOS: false,
        USER_LOGOS: false,
        INVOICE_LOGOS: false,
      }
    },
    select(key) {
      this.uploadSelections[key] = !this.uploadSelections[key]
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
